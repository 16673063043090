<template>
  <div class="wrap" v-loading="loading" id="gunlun">
    <div class="mine_info">
      <el-select v-model="on_the_job" @change="zhiweiChange">
        <el-option
          v-for="item in options"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
      
      <el-select v-model="leaveValue" @change="leavezhiweiChange" style="margin-left: 10px;">
        <el-option
          v-for="item in leaveOptions"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
      <el-select
        v-model="valueType"
        placeholder="请选择时间格式"
        @change="valueTimeChange"
        style="margin-left: 10px; width: 68px"
      >
        <el-option
          v-for="item in time"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
      <el-date-picker
        v-model="timeValue"
        :value-format="geshi"
        :type="valueType"
        placeholder="请选择时间"
        @change="timeChange"
        style="margin-left: 5px"
      >
      </el-date-picker>
      <el-table :data="applies_list" style="width: 100%">
        <el-table-column prop="title" label="日期" width="180">
        </el-table-column>
        <el-table-column prop="name" label="姓名" width="180">
          <template slot-scope="scope">
            <div>
              {{ !scope.row.name ? xm : scope.row.name }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="sign_in_time" label="上班打卡信息">
          <template slot-scope="scope">
            <div>
              <div v-if="scope.row.sign_in_time">
                {{ scope.row.sign_in_time }}
              </div>
              <div v-else-if="scope.row.sign_in_time === null">未打卡</div>
              <div v-if="scope.row.sign_in_place">
                <i class="iconfont icondiqu" style="color: #d81e06"></i
                >{{ scope.row.sign_in_place }}
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="sign_out_time" label="下班打卡信息">
          <template slot-scope="scope">
            <div>
              <div v-if="scope.row.sign_out_time">
                {{ scope.row.sign_out_time }}
              </div>
              <div v-else-if="scope.row.sign_out_time === null">未打卡</div>
              <div v-if="scope.row.sign_out_place">
                <i class="iconfont icondiqu" style="color: #d81e06"></i
                >{{ scope.row.sign_out_place }}
              </div>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div class="fenye">
        <el-pagination
          layout="prev, pager, next"
          @current-change="handleCurrentChange"
          :page-size="20"
          :current-page.sync="page"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { personnel, staff_list } from "@/api/me/index";
export default {
  name: "",
  //组件引入
  components: {},
  //状态存储
  data() {
    return {
      loading: true,
      keys: "未打卡",
      options: [],
      leaveOptions: [],
      time: [
        {
          value: "year",
          label: "年",
        },
        {
          value: "month",
          label: "月",
        },
        {
          value: "date",
          label: "日",
        },
      ],
      valueType: "date",
      value: "",
      leaveValue:"",
      on_the_job:"",
      timeValue: "",
      geshi: "yyyy-MM-dd",
      datenewData: "",
      datenewMouth: "",
      datenewYear: "",
      applies_list: [],
      page: 1,
      xm: "",
      total: 0,
    };
  },
  //计算属性
  computed: {},
  //状态监听
  watch: {},
  //存放方法集合
  methods: {
    //   获取列表数据
    list(newTime) {
      this.applies_list = [];
      this.loading = true;
      var obj = {
        lie_token: sessionStorage.getItem("lie_token"),
        page_num: this.page,
        title: newTime,
        user_id: this.value,
      };
      personnel(obj).then((res) => {
        if (res.code === "0") {
          var arr = res.context.applies_list;
          arr.forEach((item) => {
            if (item.sign_in_time) {
              item.sign_in_time = item.sign_in_time
                .replace("T", " ")
                .split(".")[0];
            }
            if (item.sign_out_time) {
              item.sign_out_time = item.sign_out_time
                .replace("T", " ")
                .split(".")[0];
            }
          });
          this.applies_list = arr;
          this.total = res.context.count;
          this.loading = false;
        }
      });
    },
    handleCurrentChange(page) {
      this.page = page;
      this.list(this.timeValue);
    },
    // 职位改变
    zhiweiChange(e) {
      //   请求接口
      this.leaveValue = ''
      this.value = e;
      this.page = 1;
      this.options.forEach((item) => {
        if (this.on_the_job == item.value) {
          this.xm = item.label;
        }
      });
      this.list(this.timeValue);
    },
    // 离职职位改变
    leavezhiweiChange(e) {
      //   请求接口
      this.on_the_job = ''
      this.value = e;
      this.page = 1;
      this.leaveOptions.forEach((item) => {
        if (this.leaveValue == item.value) {
          this.xm = item.label;
        }
      });
      this.list(this.timeValue);
    },
    // 时间改变
    timeChange(e) {
      //   请求接口
      this.page = 1;
      this.list(e);
    },
    // 时间模式发生改变
    valueTimeChange() {
      this.page = 1;
      if (this.valueType === "year") {
        this.geshi = "yyyy";
        this.timeValue = this.datenewYear;
        this.list(this.timeValue);
      } else if (this.valueType === "month") {
        this.geshi = "yyyy-MM";
        if (this.timeValue.length !== 7) {
          this.timeValue = this.datenewMouth;
        }
        this.list(this.timeValue);
      } else {
        this.geshi = "yyyy-MM-dd";
        if (this.timeValue.length !== 10) {
          this.timeValue = this.datenewData;
        }
        this.list(this.timeValue);
      }
    },
    // 获取员工id
    staff_list(is_leave,label) {
      var obj = {
        lie_token: sessionStorage.getItem("lie_token"),
        is_leave:is_leave,
      };
      staff_list(obj).then((res) => {
        if (res.code === "0") {
          var arr = [
            {
              value: "",
              label: label,
            },
          ];
          res.sub_data.forEach((item) => {
            arr.push({
              value: item.user_id,
              label: item.name,
            });
          });
          if(is_leave == 1){
            // 如果是在职就让options列表等于arr
            this.options = arr;
          }else if(is_leave == 2){
            // 如果是离职就让leaveOptions 列表等于arr
            this.leaveOptions = arr
          }
        }
      });
    },
  },
  //数据初始化之后
  created() {
    // 时间格式化
    var date = new Date();
    let year = date.getFullYear(); //获取完整的年份(4位)
    let mouth = date.getMonth() + 1; //获取当前月份(0-11,0代表1月)
    let day = date.getDate(); //获取当前日(1-31)
    mouth = mouth >= 10 ? mouth : "0" + mouth;
    day = day >= 10 ? day : "0" + day;
    let datenewYear = year.toString();
    let datenewMouth = year + "-" + mouth;
    let datenewData = year + "-" + mouth + "-" + day;
    // 年
    this.datenewYear = datenewYear;
    // 月
    this.datenewMouth = datenewMouth;
    // 日
    this.datenewData = datenewData;
    // 初始显示时间
    this.timeValue = datenewData;
    this.$nextTick(() => {
      this.list(datenewData);
    });
    // 在职员工
    this.staff_list(1,'在职员工');
    // 离职员工
    this.staff_list(2,'离职员工');
  },
  //页面渲染之后
  mounted() {},
  //数据初始化之前
  beforeCreate() {},
  //页面渲染之前
  beforeMount() {},
  //状态更新之前
  beforeUpdate() {},
  //状态更新之后
  updated() {},
  //页面卸载之前
  beforeDestroy() {},
  //卸载之后
  destroyed() {},
  //页面进入   页面缓存时有效
  activated() {},
};
</script>
<style  scoped lang='scss'>
/* //@import url(); 引入公共css类 */
.fenye {
  margin-top: 20px;
  display: flex;
  float: right;
}
.wrap {
  @include padding;
  min-width: 1100px;
  min-height: 800px;
}
.mine_info {
  max-width: 1200px;
  min-width: 900px;
  // min-height: 500px;
  padding: 20px;
  background-color: #fff;
  /* display: flex;
  align-items: center; */

  border-radius: $radius;
}
.demo-table-expand {
  font-size: 0;
}
.demo-table-expand label {
  width: 90px;
  color: #99a9bf;
}
.demo-table-expand .el-form-item {
  margin-right: 0;
  margin-bottom: 0;
  width: 50%;
}
</style>